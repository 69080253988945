import Api from './Api';

const getCurrencyStatus = (payload) => {
    return Api.postRequest({
        endpoint: '/alfa-coins-webhook',
        data: payload
    });
};

const updateInvoiceStatus = (payload) => {
    return Api.postRequest({
        method: 'PUT',
        endpoint: '/alfa-coins-webhook',
        data: payload
    });
};



export default {
    getCurrencyStatus,
    updateInvoiceStatus
};
