import Api from './Api';

const all = (payload) => {
    const path = '/site-logs';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/site-log/destroy/${payload.id}`,
    });
};

const clearErrorLog = (payload) => {
    const path = '/site-log/clear-link';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
}

const getAllUnreadSiteLogs = (payload) => {
    const path = '/site-log/get-all-unread-messages';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const siteLogsMarkAsRead = (payload) => {
    return Api.postRequest({
        endpoint: '/site-log/mark-as-read',
        method: 'PUT',
        data: payload
    });
};

export default {
    all,
    destroy,
    clearErrorLog,
    getAllUnreadSiteLogs,
    siteLogsMarkAsRead
};
