import statementHandler from "../../services/Statement";

export default {
    async loadStatements({commit, dispatch}, payload) {
        const {data} = await statementHandler.all(payload);

        commit('SET_STATEMENTS', data);
    },

    async downloadStatementAsPDF({commit, dispatch}, payload) {
        return statementHandler.downloadPDF(payload);
    }
}
