import orderDeliveryEmailHandler from "../../services/OrderDeliveryEmail";

export default {
    async sendOrderDeliveryEmail({}, payload) {
        return orderDeliveryEmailHandler.sendOrderEmail(payload);
    },
    async generateOrderDeliveryBody({}, payload) {
        return orderDeliveryEmailHandler.generateMailBody(payload);
    }
}
