import reportHandler from "../../services/Report";

export default {
    async loadDSummaries({commit}, payload) {
        return reportHandler.dSummary(payload);
    },
    async loadInvoiceByDate({commit}, payload) {
        return reportHandler.invoiceByDate(payload);
    }
}
