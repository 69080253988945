import siteOwnerHandler from "../../services/SiteOwner";

export default {
    async loadSiteOwners({commit, dispatch}, payload) {
        const {data} = await siteOwnerHandler.all(payload);

        commit('SET_SITE_OWNERS', data);
    },
    async storeSiteOwner({commit, dispatch}, payload) {
        return siteOwnerHandler.store(payload);
    },
    async updateSiteOwner({commit, dispatch}, payload) {
        return siteOwnerHandler.update(payload);
    },
    async removeSiteOwner({commit, dispatch}, payload) {
        return siteOwnerHandler.remove(payload);
    },
    async destroySiteOwner({commit, dispatch}, payload) {
        return siteOwnerHandler.destroy(payload);
    },
}
