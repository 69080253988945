import sitesHandler from "../../services/Sites";

export default {
    async loadSites({commit, dispatch}, payload) {
        const {data} = await sitesHandler.all(payload);

        commit('SET_SITES', data);
    },
    async storeSite({commit, dispatch}, payload) {
        return sitesHandler.store(payload);
    },
    async updateSite({commit, dispatch}, payload) {
        return sitesHandler.update(payload);
    },
    async removeSite({commit, dispatch}, payload) {
        return sitesHandler.remove(payload);
    },
    async destroySite({commit, dispatch}, payload) {
        return sitesHandler.destroy(payload);
    },
}
