import creditsHandler from "../../services/Credits";

export default {
    async loadCredits({commit, dispatch}, payload) {
        const {data} = await creditsHandler.all(payload);

        commit('SET_CREDITS', data);
    },
    async storeCredit({commit, dispatch}, payload) {
        return creditsHandler.store(payload);
    },
    async updateCredit({commit, dispatch}, payload) {
        return creditsHandler.update(payload);
    },
    async removeCredit({commit, dispatch}, payload) {
        return creditsHandler.remove(payload);
    },
    async destroyCredit({commit, dispatch}, payload) {
        return creditsHandler.destroy(payload);
    },
}
