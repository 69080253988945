import Api from './Api';

const all = (payload) => {
    const path = '/extra-facilities';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/extra-facilities/store',
        data: payload
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/extra-facilities/update',
        method: 'PUT',
        data: payload
    });
};

const remove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/extra-facilities/delete/${payload.id}`,
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/extra-facilities/destroy/${payload.id}`,
    });
};

export default {
    all,
    store,
    update,
    remove,
    destroy
};
