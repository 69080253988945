import methodHandler from "../../services/Methods";

export default {
    async loadMethods({commit, dispatch}, payload) {
        const {data} = await methodHandler.all(payload);

        commit('SET_METHODS', data);
    },
    async loadAllMethods({commit, dispatch}, payload) {
        const {data} = await methodHandler.getAll(payload);

        commit('SET_ALL_PAY_METHODS', data);
    },
    async storeMethod({commit, dispatch}, payload) {
        return methodHandler.store(payload);
    },
    async updateMethod({commit, dispatch}, payload) {
        return methodHandler.update(payload);
    },
    async removeMethod({commit, dispatch}, payload) {
        return methodHandler.remove(payload);
    },
    async destroyMethod({commit, dispatch}, payload) {
        return methodHandler.destroy(payload);
    },
    async bulkMethodUpdate({commit, dispatch}, payload) {
        return methodHandler.bulkUpdate(payload);
    }

}
