import BrokenLinkHandler from "../../services/BrokenLinks";

export default {
    async loadBrokenLinks({commit, dispatch}, payload) {
        const {data} = await BrokenLinkHandler.all(payload);

        commit('SET_BROKEN_LINKS', data);
    },
    async checkBrokenLink({commit, dispatch}, payload) {
        return BrokenLinkHandler.checkBrokenLink(payload);
    },
    async updateBrokenLink({commit, dispatch}, payload) {
        return BrokenLinkHandler.update(payload);
    },
    async destroyBrokenLink({commit, dispatch}, payload) {
        return BrokenLinkHandler.destroy(payload);
    },
    async clearBrokenLink({commit, dispatch}, payload) {
        return BrokenLinkHandler.clearBrokenLink(payload);
    },
    async brokenMarkAsRead({commit, dispatch}, payload) {
        return BrokenLinkHandler.brokenMarkAsRead(payload);
    }
}
