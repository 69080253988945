import siteLogsHandler from "../../services/SiteLogs";

export default {
    async loadSiteLogs({commit, dispatch}, payload) {
        const {data} = await siteLogsHandler.all(payload);

        commit('SET_SITE_LOGS', data);
    },
    async destroySiteLog({commit, dispatch}, payload) {
        return siteLogsHandler.destroy(payload);
    },
    async clearSiteLogs({commit, dispatch}, payload) {
        return siteLogsHandler.clearErrorLog(payload);
    },
    async siteLogsMarkAsRead({commit, dispatch}, payload) {
        return siteLogsHandler.siteLogsMarkAsRead(payload);
    }
}
