import Api from './Api';

const all = (payload) => {
    const path = '/mail-templates';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const update = (payload) => {
    return Api.postRequest({
        endpoint: '/mail-template/update',
        method: 'PUT',
        data: payload
    });
};

const get = (payload) => {
    return Api.getRequest({
        endpoint: `/mail-template/get-template-by-type/${payload.id}`
    });
};

const sendInvoiceEmail = (payload) => {
    return Api.postRequest({
        endpoint: '/mail-template/send-invoice-email',
        method: 'POST',
        data: payload
    });
};

const imageUpload = (payload) => {
    return Api.postRequest({
        endpoint: '/mail-template/image-upload',
        method: 'POST',
        data: payload
    });
};

export default {
    all,
    update,
    get,
    sendInvoiceEmail,
    imageUpload
};
