import salesCountersHandler from "../../services/SalesCounter";
import BrokenLinkHandler from "../../services/BrokenLinks";

export default {
    async loadSalesCounters({commit, dispatch}, payload) {
        const {data} = await salesCountersHandler.all(payload);
        commit('SET_SALES_COUNTERS', data);
    },
    async getBySalesCounterId({commit, dispatch}, payload) {
        return salesCountersHandler.get(payload);
    },
    async storeSalesCounter({commit, dispatch}, payload) {
        return salesCountersHandler.store(payload);
    },
    async updateSalesCounter({commit, dispatch}, payload) {
        return salesCountersHandler.update(payload);
    },
    async removeSalesCounter({commit, dispatch}, payload) {
        return salesCountersHandler.remove(payload);
    },
    async destroySalesCounter({commit, dispatch}, payload) {
        return salesCountersHandler.destroy(payload);
    },
    async restoreSalesCounter({commit, dispatch}, payload) {
        return salesCountersHandler.restore(payload);
    },
    async republishSalesCounter({commit, dispatch}, payload) {
        return salesCountersHandler.republish(payload);
    },
    async loadSalesCounterEmail({commit, dispatch}, payload) {
        const {data} = await salesCountersHandler.getAllEmail(payload);
        commit('SET_SALES_COUNTER_EMAIL', data);
    },
    async loadSalesReportBySite({commit, dispatch}, payload) {
        const {data} = await salesCountersHandler.salesReportBySite(payload);
        commit('SET_SALES_COUNTERS', data);
    },
    async loadSiteSummaryReports({commit, dispatch}, payload) {
        const {data} = await salesCountersHandler.siteSummaryReports(payload);
        commit('SET_SITE_SUMMARY', data);
    },
    async postRemoveSalesCounter({commit, dispatch}, payload) {
        return salesCountersHandler.postRemove(payload);
    },
    async changedPrepaymentStatus({commit, dispatch}, payload) {
        return salesCountersHandler.prepaymentStatus(payload);
    },
    destroySalesCounterWithoutPost({commit, dispatch}, payload) {
        return salesCountersHandler.deleteWithoutPost(payload);
    },
    async republishMarkAsRead({commit, dispatch}, payload) {
        return salesCountersHandler.republishMarkAsRead(payload);
    }
}
