import {mapState} from "vuex";
import pTable from "../json/pTable.json"

export default {
    data() {
      return {
          accessMenu: [
              'site-summary'
          ]
      }
    },
    computed: {
        ...mapState({
            user: state => state.user
        }),
        isAdmin() {
            return this.user && this.user.role_id === 1;
        }
    },
    methods: {
        checkAccess(type) {
            const accessTable = pTable.report[type];
            return this.user && (this.user.role_id === 1 || accessTable.find(item => item === this.user.email));
        }
    }
}