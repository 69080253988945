import mailBoxHandler from "../../services/MailBox";

export default {
    async loadMailBoxes({commit, dispatch}, payload) {
        const {data} = await mailBoxHandler.all(payload);

        commit('SET_MAIL_BOX', data);
    },
    async storeMailBox({commit, dispatch}, payload) {
        return mailBoxHandler.store(payload);
    },
    async updateMailBox({commit, dispatch}, payload) {
        return mailBoxHandler.update(payload);
    },
    async destroyMailBox({commit, dispatch}, payload) {
        return mailBoxHandler.destroy(payload);
    },
    async mailBoxSoftDeleteHandler({commit, dispatch}, payload) {
        return mailBoxHandler.softDelete(payload);
    }
}
