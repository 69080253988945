import Api from './Api';

const all = (payload) => {
    const path = '/failed-queued-logs';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const clear = (payload) => {
    const path = '/failed-queued-log/clear-all-failed-jobs';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
}

export default {
    all,
    clear
};
