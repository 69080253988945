import nicheCategoriesHandler from "../../services/NicheCategories";

export default {
    async loadNicheCategories({commit, dispatch}, payload) {
        const {data} = await nicheCategoriesHandler.all(payload);

        commit('SET_NICHE_CATEGORIES', data);
    },
    async loadNicheCategoriesByStatus({commit, dispatch}, payload) {
        const {data} = await nicheCategoriesHandler.getByStatus(payload);

        commit('SET_NICHE_CATEGORIES', data);
    },
    async storeNicheCategory({commit, dispatch}, payload) {
        return nicheCategoriesHandler.store(payload);
    },
    async updateNicheCategory({commit, dispatch}, payload) {
        return nicheCategoriesHandler.update(payload);
    },
    async removeNicheCategory({commit, dispatch}, payload) {
        return nicheCategoriesHandler.remove(payload);
    },
    async destroyNicheCategory({commit, dispatch}, payload) {
        return nicheCategoriesHandler.destroy(payload);
    },
}
