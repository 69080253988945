import linksHandler from "../../services/Links";

export default {
    async loadLinks({commit, dispatch}, payload) {
        const {data} = await linksHandler.all(payload);

        commit('SET_ALLOWED_LINKS', data);
    },
    async getLink({commit, dispatch}, payload) {
        return linksHandler.checkLink(payload);
    },
    async updateLinks({commit, dispatch}, payload) {
        return linksHandler.update(payload);
    }
}
