import Api from './Api';

const all = (payload) => {
    const path = '/sites';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const getByOne = (payload) => {
    const path = '/site/find-by-one';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;
    return Api.getRequest({
        endpoint
    });
};

const getBulkSite = (payload) => {
    return Api.postRequest({
        endpoint: '/site/get-bulk',
        data: payload
    });
};

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/site/store',
        data: payload
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/site/update',
        method: 'PUT',
        data: payload
    });
};
const remove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/site/delete/${payload.id}`,
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/site/destroy/${payload.id}`,
    });
};

export default {
    all,
    store,
    update,
    remove,
    destroy,
    getByOne,
    getBulkSite
};
