<template>
    <v-menu
            :close-on-content-click="menu"
            min-width="300"
            offset-y
            class="mt-20 mr-2"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                    :content="messages"
                    :value="messages"
                    color="error"
                    overlap
            >
                <v-btn
                        fab
                        dark
                        x-small
                        color="primary"
                        link
                        v-on="on"
                >
                    <v-icon>
                      {{icon}}
                    </v-icon>
                </v-btn>
            </v-badge>
        </template>
        <v-card
                class="mx-auto"
                max-width="500"
                max-height="500"
                v-if="messages > 0"
        >
            <v-card-title>
                <h2 class="text-h6">
                    {{title}}
                </h2>
                <v-spacer></v-spacer>
                <a class="subtitle-2" @click.prevent.stop="setMarkAsRead('all')">Mark as all read</a>
            </v-card-title>
            <v-divider></v-divider>
            <slot></slot>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
      name: "BaseNotification",
      data() {
        return {
          menu: false,
        }
      },
      props: {
        messages: {
          type: Number,
          default: function () {
            return 0;
          }
        },
        title: {
          type: String,
          default: ''
        },
        icon: {
          type: String,
          default: 'mdi-alpha-i-circle-outline'
        }
      },
      methods: {
        setMarkAsRead(event) {
          this.$emit('setMarkAsRead', event)
        }
      }

    }
</script>

<style type="scss" scoped>
    .v-chip.v-size--default {
        height: 20px !important;
    }

    .v-card__subtitle, .v-card__text, .v-card__title {
        padding: 10px !important;
    }
</style>