import Api from './Api';

const all = (payload) => {
    const path = '/payments';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/credit/store',
        data: payload
    });
};

const remove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/credit/delete/${payload.id}`,
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/credit/destroy/${payload.id}`,
    });
};

export default {
    all,
    store,
    remove,
    destroy
};
