import orderHandler from "../../services/Orders";

export default {
    async loadOrders({commit}, payload) {
        const {data} = await orderHandler.all(payload);

        commit('SET_ORDERS', data);
    },
    async updateOrder({}, payload) {
        return orderHandler.update(payload);
    }
}
