import Api from './Api';

const all = (payload) => {
    const path = '/methods';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const getAll = (payload) => {
    const path = '/methods/get-all';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/method/store',
        data: payload
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/method/update',
        method: 'PUT',
        data: payload
    });
};

const bulkUpdate = (payload) => {
    return Api.postRequest({
        endpoint: '/method/bulk-update',
        method: 'PUT',
        data: payload
    });
};

const remove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/method/delete/${payload.id}`,
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/method/destroy/${payload.id}`,
    });
};

export default {
    all,
    store,
    update,
    remove,
    bulkUpdate,
    destroy,
    getAll
};
