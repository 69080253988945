import failedQueuedLogsHandler from "../../services/FailedQueuedLogs";

export default {
    async loadFailedQueuedLogs({commit, dispatch}, payload) {
        const {data} = await failedQueuedLogsHandler.all(payload);

        commit('SET_FAILED_QUEUED_LOGS', data);
    },
    async clearFailedQueuedLogs({commit, dispatch}, payload) {
        return failedQueuedLogsHandler.clear(payload);
    }
}
