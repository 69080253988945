import fixClientHandler from "../../services/FixClients";

export default {
    async loadFixClients({commit, dispatch}, payload) {
        const {data} = await fixClientHandler.all(payload);

        commit('SET_FIX_CLIENTS', data);
    },
    async storeFixClient({commit, dispatch}, payload) {
        return fixClientHandler.store(payload);
    },
    async updateFixClient({commit, dispatch}, payload) {
        return fixClientHandler.update(payload);
    },
    async removeFixClient({commit, dispatch}, payload) {
        return fixClientHandler.remove(payload);
    },
    async destroyFixClient({commit, dispatch}, payload) {
        return fixClientHandler.destroy(payload);
    },
}
