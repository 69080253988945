export default {
  SET_SALES_COUNTERS: function (state, payload) {
    state.salesCounters = payload;
  },
  SET_SALES_COUNTER_EMAIL: function (state, payload) {
    state.salesEmails = payload;
  },
  SET_SITE_SUMMARY: function (state, payload) {
    state.sitesSummaries = payload
  }
}
