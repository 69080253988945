import Api from './Api';

const sendOrderEmail = (payload) => {
    return Api.postRequest({
        endpoint: 'order-delivery/send-email',
        data: payload
    });
};

const generateMailBody = (payload) => {
    return Api.postRequest({
        endpoint: 'order-delivery/generate-mail-body',
        data: payload
    });
};

export default {
    sendOrderEmail,
    generateMailBody
};