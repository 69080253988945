import deliveryEmailLogsHandler from "../../services/DeliveryEmailLogs";

export default {
    async loadDeliveryEmailLogs({commit, dispatch}, payload) {
        const {data} = await deliveryEmailLogsHandler.all(payload);

        commit('SET_DELIVERY_EMAIL_LOGS', data);
    },
    async clearDeliveryEmailLogs({commit, dispatch}, payload) {
        return deliveryEmailLogsHandler.clear(payload);
    }
}
