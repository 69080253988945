import newsLetterMailTemplateHandler from "../../services/NewsLetterMailTemplate";

export default {
    async loadNewsLetterMailTemplates({commit, dispatch}, payload) {
        const {data} = await newsLetterMailTemplateHandler.all(payload);

        commit('SET_NEWS_LETTER_MAIL_TEMPLATE', data);
    },
    async sendNewsLetter({commit, dispatch}, payload) {
        return newsLetterMailTemplateHandler.sendNewsLetter(payload);
    },
}
