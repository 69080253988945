import Sortable from "sortablejs"
const SortableTable = {
    bind (el, binding, vnode) {
        if(el.dataset['scrollable'] && el.dataset['scrollable'] === 'method') {
        const options = {
            animation: 150,
            onUpdate: function (event) {
                vnode.child.$emit('sorted', event);
            }
        };
            Sortable.create(el.getElementsByTagName('tbody')[0], options);
        }
    }
};

export default SortableTable;