import siteOwnershipHandler from "../../services/SiteOwnership";

export default {
    async loadSiteOwnerships({commit, dispatch}, payload) {
        const {data} = await siteOwnershipHandler.all(payload);

        commit('SET_SITE_OWNERSHIPS', data);
    },
    async updateSiteOwnership({commit, dispatch}, payload) {
        return siteOwnershipHandler.update(payload);
    }
}
