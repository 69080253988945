import Api from './Api';

const all = (payload) => {
    const path = '/all-invoices';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/invoice/store',
        data: payload
    });
};

const get = (payload) => {
    return Api.getRequest({
        endpoint: `/invoice/get-by-invoice-id/${payload.id}`,
    });
};

const updateManualPayment = (payload)=> {
    return Api.postRequest({
        endpoint: '/invoice/update-manual-payment',
        method: 'PUT',
        data: payload
    });
}

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/invoice/update',
        method: 'PUT',
        data: payload
    });
};

const remove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/invoice/delete/${payload.id}`,
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/invoice/destroy/${payload.id}`,
    });
};

const restore = (payload) => {
    return Api.postRequest({
        endpoint: '/invoice/restore',
        method: 'PUT',
        data: payload
    });
};

const resetInvoice = (payload) => {
    return Api.postRequest({
        endpoint: '/invoice/reset-invoice',
        method: 'PUT',
        data: payload
    });
};

const paymentMail = (payload) => {
    return Api.postRequest({
        endpoint: '/invoice/resend-payment-mail',
        data: payload
    });
};

const reminderMail = (payload) => {
    return Api.postRequest({
        endpoint: `/invoice/reminder-mail`,
        data: payload
    });
};

const afterReminderMail = (payload) => {
    return Api.postRequest({
        endpoint: `/invoice/after-reminder-mail`,
        data: payload
    });
};

const getAllUnreadMessages = (payload) => {
    const path = '/get-all-unread-messages';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const invoiceMarkAsRead = (payload) => {
    return Api.postRequest({
        endpoint: '/invoice/mark-as-read',
        method: 'PUT',
        data: payload
    });
};

const refund = (payload) => {
    return Api.getRequest({
        endpoint: `/invoice/refund/${payload.id}`,
    });
}

const getInvoiceDescriptionByEmail = (payload) => {
    const path = '/invoice/description-by-email';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

export default {
    all,
    get,
    store,
    updateManualPayment,
    update,
    destroy,
    remove,
    restore,
    resetInvoice,
    paymentMail,
    reminderMail,
    afterReminderMail,
    getAllUnreadMessages,
    invoiceMarkAsRead,
    refund,
    getInvoiceDescriptionByEmail
};
