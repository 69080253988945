import clientHandler from "../../services/Clients";

export default {
    async loadClients({commit, dispatch}, payload) {
        const {data} = await clientHandler.all(payload);

        commit('SET_CLIENTS', data);
    },
    async storeClient({commit, dispatch}, payload) {
        return clientHandler.store(payload);
    },
    async updateClient({commit, dispatch}, payload) {
        return clientHandler.update(payload);
    },
    async removeClient({commit, dispatch}, payload) {
        return clientHandler.remove(payload);
    },
    async destroyClient({commit, dispatch}, payload) {
        return clientHandler.destroy(payload);
    },
}
