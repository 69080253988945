<template>
<base-notification
    :messages="messages"
    title="Invoice Notification"
    @setMarkAsRead="setMarkAsRead"
>
  <v-list two-line>
    <v-list-item-group
        v-model="selected"
        active-class="pink--text"
        return-object
        @change="setMarkAsRead($event)"
    >
      <template v-for="(item, index) in items">
        <v-list-item :key="item.title">
          <template v-slot:default="{ active }">
            <v-list-item-content>
              <v-list-item-title> Invoice No. #{{item.invoiceNo}}
                <g-payment-status-chip v-if="item.isDeleted" text="Deleted" color="blue"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 100"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 200" color="green" text="Paid"/>
              </v-list-item-title>
              <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.email"
              ></v-list-item-subtitle>
              <v-list-item-subtitle v-html="getText(item)"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text v-text="getTimeDiff(item.paymentDate)"></v-list-item-action-text>
              <v-icon
                  v-if="!active"
                  color="grey lighten-1"
              >
                mdi-star-outline
              </v-icon>

              <v-icon
                  v-else
                  color="yellow darken-3"
              >
                mdi-star
              </v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-divider
            v-if="index < items.length - 1"
            :key="index"
        ></v-divider>
      </template>
    </v-list-item-group>
    <div
        v-if="!isLastPage"
        v-observe-visibility="{callback: visibilityChanged}"
        class="d-flex justify-center mb-6"
    >
      <v-progress-circular
          indeterminate
          color="primary"
      />
    </div>
  </v-list>

</base-notification>
</template>

<script>
import BaseNotification from "./BaseNotification";
import NotificationMixin from "../mixins/NotificationMixin";
export default {
  name: "InvoiceNotification",
  components: {BaseNotification},
  mixins: [NotificationMixin],
  data() {
    return {
      actions: {
        update: 'invoiceMarkAsRead'
      },
      broadcastChannel:  'payment-notification-channel',
      notificationChannel: 'payment-channel',
      apiPath: 'get-all-unread-messages',
      descending: true,
      sortBy: 'paymentDate'
    }
  }
}
</script>

<style scoped>

</style>