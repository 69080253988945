import mailTemplateHandler from "../../services/MailTemplate";

export default {
    async loadMailTemplates({commit, dispatch}, payload) {
        const {data} = await mailTemplateHandler.all(payload);

        commit('SET_MAIL_TEMPLATE', data);
    },
    async updateMailTemplate({commit, dispatch}, payload) {
        return mailTemplateHandler.update(payload);
    },
    async getTemplateByType({commit, dispatch}, payload) {
        return mailTemplateHandler.get(payload);
    },
    async sendInvoiceEmail({commit, dispatch}, payload) {
        return mailTemplateHandler.sendInvoiceEmail(payload);
    },
}
