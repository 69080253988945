import Api from "../../services/Api";
import GPaymentStatusChip from "../GPaymentStatusChip";
import DateHelper from "./DateHelper";
export default {
    components: {
        GPaymentStatusChip
    },
    mixins: [DateHelper],
    data() {
        return {
            messages: 0,
            isLastPage: false,
            isShowLoading: true,
            selected: [],
            items: [],
            pagination: {
                page: 1,
                rowsPerPage: 10,
                descending: true,
                sortBy: 'paymentDate'
            },
            currentPage: 0
        }
    },
    mounted() {
        this.connect();
        this.getMessages();
    },
    methods: {
        visibilityChanged(e) {
            if(e) {
                this.getMessages(true);
            }
        },
        getMessages(newPage = false) {
            this.selected = [];
            if (newPage) {
                ++this.currentPage;
            } else {
                this.currentPage = 1;
            }

            this.pagination.page = this.currentPage;

            const path = `/${this.apiPath}?rowsPerPage=10&descending=${this.descending}&page=${this.currentPage}&sortBy=${this.sortBy}`;
            Api.getRequest({endpoint: path})
                .then((response) => {
                    this.items = this.items.concat(response.data.data);
                    this.isLastPage = this.items.length === response.data.total;
                    this.messages = response.data.total;
                    this.isShowLoading = false;
                })
        },
        connect() {
            let channel = this.$pusher.subscribe(`private-${this.notificationChannel}`);
            let me = this;
            channel.bind(this.broadcastChannel, (data)=> {
                me.items = [];
                me.getMessages();
            })
        },
        setMarkAsRead(event) {
            let id = '';
            if(event === 'all') {
                id = 'all';
            } else {
                let item = this.items[event];
                id = item.id;
            }

            this.$store.dispatch(this.actions.update, {id})
                .then(({data}) => {
                    this.$store.commit('SET_SNACKBAR', {text: data.message, type: 'info'});
                    this.currentPage = 1;
                    this.items = [];
                    this.getMessages();
                })
        },
        getText(item) {
            let text = '';
            let breakpoint = this.$vuetify.breakpoint.name;
            if(item.isDeleted) {
                text = text + `This invoice has been deleted `+ breakpoint === 'sm' || breakpoint === 'xs' ? '<br>': ''
            } else if (item.paymentStatus === 200) {
                text = text + `Payment has been paid with <strong>${item.methods ? item.methods.name : ''}</strong> ${breakpoint === 'sm' || breakpoint === 'xs' ? '<br>': ''}`
            } else {
                text = text + `This invoice has been newly created ` + breakpoint === 'sm' || breakpoint === 'xs' ? '<br>': ''
            }

            text = text + `and total amount is $${item.totalAmount}`

            return text;
        },
        getRepublishText(item) {
            return `This order total amount is $${item.totalAmount}`
        }
    }
}