import Api from './Api';

const all = (payload) => {
    const path = '/google-sheets';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/google-sheet/update',
        method: 'PUT',
        data: payload
    });
};
const sync = (payload) => {
    return Api.postRequest({
        endpoint: '/google-sheet/sync',
        method: 'PUT',
        data: payload
    });
};

const syncMoz = (payload) => {
    return Api.postRequest({
        endpoint: '/google-sheet/sync-moz',
        method: 'PUT',
        data: payload
    });
};

export default {
    all,
    update,
    sync,
    syncMoz
};
