<template>
<base-notification
    :messages="messages"
    title="Broken Link Notification"
    @setMarkAsRead="setMarkAsRead"
    icon="mdi-alpha-b-circle-outline"
>
  <v-list two-line>
    <v-list-item-group
        v-model="selected"
        active-class="pink--text"
        return-object
        @change="setMarkAsRead($event)"
    >
      <template v-for="(item, index) in items">
        <v-list-item :key="item.orderId">
          <template v-slot:default="{ active }">
            <v-list-item-content>
              <v-list-item-title class="mb-3"> Order ID. #{{item.orderId}}</v-list-item-title>

              <v-list-item-subtitle>Link: <a v-bind:href="item.liveLink" target="_blank">{{item.liveLink}}</a></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text v-text="getTimeDiff(item.created_at)"></v-list-item-action-text>
              <v-icon
                  v-if="!active"
                  color="grey lighten-1"
              >
                mdi-star-outline
              </v-icon>

              <v-icon
                  v-else
                  color="yellow darken-3"
              >
                mdi-star
              </v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-divider
            v-if="index < items.length - 1"
            :key="index"
        ></v-divider>
      </template>
    </v-list-item-group>
    <div
        v-if="!isLastPage"
        v-observe-visibility="{callback: visibilityChanged}"
        class="d-flex justify-center mb-6"
    >
      <v-progress-circular
          indeterminate
          color="primary"
      />
    </div>
  </v-list>
</base-notification>
</template>

<script>
import BaseNotification from "./BaseNotification";
import NotificationMixin from "../mixins/NotificationMixin";
export default {
  name: "BrokenLinkNotifications",
  components: {BaseNotification},
  mixins: [NotificationMixin],
  data() {
    return {
      actions: {
        update: 'brokenMarkAsRead'
      },
      broadcastChannel:  'broken-link-notify-event',
      notificationChannel: 'broken-link-notify-channel',
      apiPath: 'broken-link/get-all-unread-messages',
      descending: true,
      sortBy: 'id'
    }
  }
}
</script>

<style scoped>

</style>