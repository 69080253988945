import taskSchedulerHandler from "../../services/TaskScheduler";

export default {
    async loadTaskSchedulers({commit, dispatch}, payload) {
        const {data} = await taskSchedulerHandler.all(payload);

        commit('SET_TASK_SCHEDULER', data);
    },
    async storeTaskScheduler({commit, dispatch}, payload) {
        return taskSchedulerHandler.store(payload);
    },
    async updateTaskScheduler({commit, dispatch}, payload) {
        return taskSchedulerHandler.update(payload);
    },
    async destroyTaskScheduler({commit, dispatch}, payload) {
        return taskSchedulerHandler.destroy(payload);
    },
    async removeTaskScheduler({commit, dispatch}, payload) {
        return taskSchedulerHandler.softDelete(payload);
    }
}
