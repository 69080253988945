import moment from "moment";

export default {
    methods: {
        getDateTime(date) {
            if(!date) return;
            return moment(date).format('MMM D,  YYYY hh:mm:ss A');
        },
        getDate(date) {
            if(!date) return;
            return moment(date).format('MMM D,  YYYY');
        },
        getTimeDiff(dateTime) {
            if(!dateTime) return;
            let minutes = moment().diff(moment(dateTime), 'minutes');
            let hours = moment().diff(moment(dateTime), 'hours');
            let days = moment().diff(moment(dateTime), 'days');
            if(minutes === 0) {
                return 'just now';
            }
            else if(minutes <= 60) {
                return minutes+' min';
            } else if(hours <= 24) {
                return hours+' hr';
            } else if(days <= 1){
                return days+' day';
            } else {
                return days+' days';
            }
        }
    }
}