import newsletterLogsHandler from "../../services/NewsletterLogs";

export default {
    async loadNewsletterLogs({commit, dispatch}, payload) {
        const {data} = await newsletterLogsHandler.all(payload);

        commit('SET_NEWSLETTER_LOGS', data);
    },
    async clearNewsletterLogs({commit, dispatch}, payload) {
        return newsletterLogsHandler.clear(payload);
    }
}
