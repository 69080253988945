import Api from './Api';

const all = (payload) => {
    const path = '/order';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/order/update',
        data: payload
    });
};

const refund = (payload) => {
    return Api.postRequest({
        endpoint: '/order/refund',
        data: payload
    });
};

export default {
    all,
    update,
    refund
};