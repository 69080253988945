import googleSheetSyncHandler from "../../services/GoogleSheetSync";

export default {
    async loadGoogleSheets({commit, dispatch}, payload) {
        const {data} = await googleSheetSyncHandler.all(payload);

        commit('SET_GOOGLE_SHEETS_SYNC', data);
    },
    async updateGoogleSheets({commit, dispatch}, payload) {
        return googleSheetSyncHandler.update(payload);
    },
    async syncGoogleSheet({commit, dispatch}, payload) {
        return googleSheetSyncHandler.sync(payload);
    },
    async syncMozGoogleSheet({commit, dispatch}, payload) {
        return googleSheetSyncHandler.syncMoz(payload);
    },
}
