import sitesStatementHandler from "../../services/SitesStatement";

export default {
    async loadSitesStatement({commit, dispatch}, payload) {
        const {data} = await sitesStatementHandler.all(payload);

        commit('SET_SITES_STATEMENT', data);
    },

    async downloadSitesStatementAsPDF({commit, dispatch}, payload) {
        return sitesStatementHandler.downloadPDF(payload);
    }
}
