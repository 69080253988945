import Api from './Api';

const all = (payload) => {
    const path = '/delivery-email-logs';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const clear = (payload) => {
    const path = '/delivery-email-log/clear-link';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
}

export default {
    all,
    clear
};
