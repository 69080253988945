import cryptocurrencyHandler from "../../services/Cryptocurrency";

export default {
    async storeCredit({commit, dispatch}, payload) {
        return cryptocurrencyHandler.getCurrencyStatus(payload);
    },
    async updateInvoiceStatus({commit, dispatch}, payload) {
        return cryptocurrencyHandler.updateInvoiceStatus(payload);
    }
}
