import extraFacilitiesHandler from "../../services/ExtraFacilities";

export default {
    async loadExtraFacilities({commit, dispatch}, payload) {
        const {data} = await extraFacilitiesHandler.all(payload);

        commit('SET_EXTRA_FACILITIES', data);
    },
    async storeExtraFacility({commit, dispatch}, payload) {
        return extraFacilitiesHandler.store(payload);
    },
    async updateExtraFacility({commit, dispatch}, payload) {
        return extraFacilitiesHandler.update(payload);
    },
    async removeExtraFacility({commit, dispatch}, payload) {
        return extraFacilitiesHandler.remove(payload);
    },
    async destroyExtraFacility({commit, dispatch}, payload) {
        return extraFacilitiesHandler.destroy(payload);
    },
}
