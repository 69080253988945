import staffHandler from "../../services/Staff";

export default {
    async loadStaffs({commit, dispatch}, payload) {
        const {data} = await staffHandler.all(payload);

        commit('SET_STAFFS', data);
    },

    async downloadStaffAsPDF({commit, dispatch}, payload) {
        return staffHandler.downloadPDF(payload);
    }
}
