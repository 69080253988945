import wpAccessHandler from "../../services/WPAccess";

export default {
    async loadWPSites({commit, dispatch}, payload) {
        const {data} = await wpAccessHandler.all(payload);

        commit('SET_WP_SITES', data);
    },
    async updateWPSite({commit, dispatch}, payload) {
        return wpAccessHandler.store(payload);
    },
    async syncWPAccess({commit, dispatch}, payload) {
        return wpAccessHandler.syncWPAccess(payload);
    },
    async resetWPAccess({commit, dispatch}, payload) {
        return wpAccessHandler.resetWPAccess(payload);
    }
}
